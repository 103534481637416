import React, { useEffect } from "react";
import AppStore from "@assets/AppStore.png";
import GooglePlay from "@assets/GooglePlay.png";
import { t } from "@/localization/setup";
import { useParams } from "react-router-dom";
import MobileDetect from "mobile-detect";

export default function Share() {
  const { username } = useParams();

  useEffect(() => {
    const md = new MobileDetect(window.navigator.userAgent);
    const OS = md.os();

    if (OS === "AndroidOS") {
      window.location.replace(
        `https://play.google.com/store/apps/details?id=com.getdogsapp&recruit=${username}`
      );
    } else if (["iOS", "iPadOS"].includes(OS)) {
      window.location.replace(
        `https://apps.apple.com/se/app/dogs/id1601668360?recruit=${username}`
      );
    }
  }, []);

  return (
    <section className="px-4 py-8 md:py-20">
      <article className="relative prose lg:prose-lg mx-auto pb-20">
        <h1 className="text-center">{t("You received an invite!")}</h1>
        <p className="text-center">
          {t(
            "You have received an invite from {{username}}. Download our app and say hi!",
            { username: "flivijn" }
          )}
        </p>

        <div className="mt-6 flex justify-center items-center space-x-3">
          <a href="https://apps.apple.com/se/app/dogs/id1601668360">
            <img className="h-12 w-auto" src={AppStore} alt="Apple Logo" />
          </a>

          <a href="https://play.google.com/store/apps/details?id=com.getdogsapp">
            <img className="h-12 w-auto" src={GooglePlay} alt="Google Logo" />
          </a>
        </div>
      </article>
    </section>
  );
}
