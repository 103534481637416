import React from "react";
import ReactMarkdown from "react-markdown";

export default function MarkdownPage(props: any) {
  return (
    <section className="px-4 py-8 md:py-20">
      <article className="prose prose-lg mx-auto">
        <ReactMarkdown>{props.children}</ReactMarkdown>
      </article>
    </section>
  );
}
