import React from "react";
import { Outlet } from "react-router-dom";
import { SocialIcon } from "react-social-icons";
import Logo from "@assets/Logo.png";
import { t } from "@/localization/setup";
import AppStore from "@assets/AppStore.png";
import GooglePlay from "@assets/GooglePlay.png";
import Menu from "./components/Menu";
import { menuItems } from "./library/menu-items";

export default function App() {
  return (
    <div>
      <Menu />

      <main className="pt-[76px] lg:pt-0">
        <Outlet />

        <section className="bg-primary-100 bg-gradient-to-br from-primary-100 to-primary-50 relative overflow-hidden py-20 sm:py-28">
          <div className="absolute top-1/2 left-1/2 -translate-y-1/2 -translate-x-1/2">
            <svg
              viewBox="0 0 558 558"
              fill="none"
              aria-hidden="true"
              className="w-[440px] h-[440px] lg:w-[600px] lg:h-[600px] animate-spin-slow"
            >
              <defs>
                <linearGradient
                  id=":R1hm:"
                  x1="79"
                  y1="16"
                  x2="105"
                  y2="237"
                  gradientUnits="userSpaceOnUse"
                >
                  <stop stopColor="#ed8936"></stop>
                  <stop offset="1" stopColor="#ed8936" stopOpacity="0"></stop>
                </linearGradient>
              </defs>
              <path
                opacity=".2"
                d="M1 279C1 125.465 125.465 1 279 1s278 124.465 278 278-124.465 278-278 278S1 432.535 1 279Z"
                stroke="#ed8936"
              ></path>
              <path
                d="M1 279C1 125.465 125.465 1 279 1"
                stroke="url(#:R1hm:)"
                strokeLinecap="round"
              ></path>
            </svg>
          </div>

          <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8 relative">
            <div className="mx-auto max-w-md text-center">
              <h2 className="font-black tracking-tight text-black text-3xl sm:text-4xl lg:text-5xl">
                Join the pack<span className="text-primary-500">.</span>
              </h2>
              <p className="mt-4 text-lg text-primary-900">
                {t("Download Footer Text")}
              </p>
              <div className="mt-8 flex justify-center space-x-4">
                <a href="https://apps.apple.com/se/app/dogs/id1601668360">
                  <img
                    className="h-10 lg:h-12 w-auto px-2 py-0.5 bg-black rounded"
                    src={AppStore}
                    alt="App Store"
                  />
                </a>

                <a href="https://play.google.com/store/apps/details?id=com.getdogsapp">
                  <img
                    className="h-10 lg:h-12 w-auto px-2 py-0.5 bg-black rounded"
                    src={GooglePlay}
                    alt="Google Play"
                  />
                </a>
              </div>
            </div>
          </div>
        </section>
      </main>

      <footer className="bg-white p-6 py-12 lg:p-12">
        <div className="flex justify-center">
          <img className="h-12 w-auto" src={Logo} alt="Icon" />
        </div>

        <div className="mt-6 flex flex-wrap items-center justify-center">
          {menuItems.map((item, i) => (
            <a
              key={i}
              href={item.to}
              className="mx-3 my-1.5 text-sm text-gray-500 tracking-wide hover:underline"
            >
              {t(item.title)}
            </a>
          ))}
        </div>

        <div className="mt-12 w-full max-w-screen-xl mx-auto border-t border-gray-200/50 md:flex justify-between p-4">
          <div className="text-center text-gray-500 text-sm">
            &copy; Copyright 2022 Dogs Unleashed AB.
          </div>

          <div className="mt-4 md:mt-0 text-center space-x-2">
            <SocialIcon
              url="https://facebook.com/getdogsapp"
              style={{ width: 24, height: 24 }}
              target="_blank"
              rel="noreferrer"
            />
            <SocialIcon
              url="https://twitter.com/getdogsapp"
              style={{ width: 24, height: 24 }}
              target="_blank"
              rel="noreferrer"
            />
            <SocialIcon
              url="https://instagram.com/get_dogs_app"
              style={{ width: 24, height: 24 }}
              target="_blank"
              rel="noreferrer"
            />
            <SocialIcon
              url="https://linkedin.com/company/dogsunleashed"
              style={{ width: 24, height: 24 }}
              target="_blank"
              rel="noreferrer"
            />
          </div>
        </div>
      </footer>
    </div>
  );
}
