import React, { useState } from "react";
import { Link } from "react-router-dom";
import Logo from "@assets/Logo.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import { lng, t } from "@/localization/setup";
import { menuItems } from "@/library/menu-items";
import FadeTransition from "@/components/FadeTransition";
import sv from "@/assets/sv.svg";
import en from "@/assets/en.svg";

export default function Menu() {
  const [open, setOpen] = useState(false);

  return (
    <>
      <header className="fixed z-50 lg:relative top-0 bg-white border-t-4 border-primary-500 w-full">
        <div className="max-w-screen-xl mx-auto z-10 flex flex-row items-center justify-between items-center p-3 lg:p-4 xl:px-0">
          <a href="/">
            <img
              className="w-auto h-10 object-cover"
              src={Logo}
              alt="Dogs Icon"
            />
          </a>

          <button
            className="lg:hidden p-3 text-gray-900"
            onClick={() => setOpen(true)}
          >
            <FontAwesomeIcon icon={solid("bars")} />
          </button>

          <div className="flex-1 hidden lg:flex pl-16 items-center space-x-3">
            {menuItems
              .filter((item) => !item.onlyFooter)
              .map((item, i) => (
                <a
                  key={i}
                  href={item.to}
                  className={`text-sm text-gray-700 tracking-wide rounded px-3 py-1 hover:text-gray-900 hover:bg-gray-100`}
                >
                  {t(item.title)}
                </a>
              ))}

            <a
              href={`/?lang=${lng === "en" ? "sv" : "en"}`}
              className="rounded px-3 py-2 hover:text-gray-900 hover:bg-gray-100"
            >
              <img className="h-3 w-auto" src={lng === "en" ? sv : en} />
            </a>
          </div>

          <Link
            to="/download"
            className="hidden lg:inline bg-secondary-500 rounded-full py-2 px-4 font-bold text-sm text-white hover:bg-secondary-600"
          >
            {t("Download Dogs")}
            <FontAwesomeIcon icon={solid("circle-down")} className="ml-2" />
          </Link>
        </div>
      </header>

      <FadeTransition visible={open}>
        <nav className="fixed inset-0 z-50 bg-white p-6 lg:hidden">
          <button
            className="fixed top-4 right-4 z-50 p-3 text-black"
            onClick={() => setOpen(false)}
          >
            <FontAwesomeIcon icon={solid("times")} />
          </button>

          <div className="h-full flex flex-col items-center justify-center gap-3">
            {menuItems
              .filter((item) => !item.onlyFooter)
              .map((item, i) => (
                <a
                  key={i}
                  href={item.to}
                  className={`text-xl text-gray-900 tracking-wide rounded px-3 py-1 hover:text-gray-900 hover:bg-gray-100`}
                  onClick={() => setOpen(false)}
                >
                  {t(item.title)}
                </a>
              ))}

            <Link
              to="/download"
              className="mt-3 bg-secondary-500 rounded-full py-2 px-4 font-bold text-sm text-white text-center hover:bg-secondary-600"
              onClick={() => setOpen(false)}
            >
              {t("Download Dogs")}
              <FontAwesomeIcon icon={solid("circle-down")} className="ml-2" />
            </Link>

            <a
              href={`/?lang=${lng === "en" ? "sv" : "en"}`}
              className="rounded px-3 py-2 hover:text-gray-900 hover:bg-gray-100"
            >
              <img className="h-3 w-auto" src={lng === "en" ? sv : en} />
            </a>
          </div>
        </nav>
      </FadeTransition>
    </>
  );
}
