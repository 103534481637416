import React from "react";
import ReactDOM from "react-dom";
import "./index.scss";
import App from "./App";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Home from "./routes/Home";
import Privacy from "./routes/Privacy";
import Terms from "./routes/Terms";
import Share from "@/routes/Share";
import Download from "@/routes/Download";
import Recruit from "./routes/Recruit";
import { createBrowserHistory } from "history";

const history = createBrowserHistory();
history.listen(({ location }) => {
  (window as any).gtag("event", "page_view", {
    page_path: location.pathname + location.search,
  });
});

ReactDOM.render(
  <BrowserRouter>
    <React.StrictMode>
      <Routes>
        <Route path="/" element={<App />}>
          <Route path="" element={<Home />} />
          <Route path="privacy-policy" element={<Privacy />} />
          <Route path="terms-of-service" element={<Terms />} />
          <Route path="download" element={<Download />} />
          <Route path="users/:username" element={<Share />} />
          <Route path="dogs/:id/:name" element={<Share />} />
          <Route path="feeds/:feed/:id" element={<Share />} />
          <Route path="places/:id/:name" element={<Share />} />
          <Route path="activities/:id" element={<Share />} />
          <Route path="r/:username" element={<Recruit />} />
          <Route path="*" element={<Home />} />
        </Route>
      </Routes>
    </React.StrictMode>
  </BrowserRouter>,
  document.getElementById("root")
);
