import React, { useEffect, useState } from "react";
import { t } from "@/localization/setup";
import AppStore from "@assets/AppStore.png";
import GooglePlay from "@assets/GooglePlay.png";
import Training from "@assets/Prints/Training.png";
import Medicine from "@assets/Prints/Medicine.png";
import ExplorePeople from "@assets/Prints/ExplorePeople.png";
import Feed from "@assets/Prints/Feed.png";
import Walk from "@assets/Prints/Walk.jpeg";
import Places from "@assets/Prints/Places.png";
import Louise from "@assets/People/Louise.jpeg";
import Fredrik from "@assets/People/Fredrik.jpeg";
import Andre from "@assets/People/Andre.jpeg";
import Sofia from "@assets/People/Sofia.jpeg";
import Ceasar from "@assets/People/Ceasar.jpg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import { IconDefinition } from "@fortawesome/free-solid-svg-icons";
import FadeTransition from "@/components/FadeTransition";

const features: {
  id: string;
  icon: IconDefinition;
  image: string;
  subfeatures: IconDefinition[];
}[] = [
  {
    id: "medicine",
    icon: solid("syringe"),
    image: Medicine,
    subfeatures: [
      solid("chart-mixed"),
      solid("id-card"),
      solid("notes-medical"),
      solid("shield-check"),
    ],
  },
  {
    id: "training",
    icon: solid("book"),
    image: Training,
    subfeatures: [solid("dog"), solid("medal"), solid("share-alt")],
  },
  {
    id: "walks",
    icon: solid("dog-leashed"),
    image: Walk,
    subfeatures: [solid("wave-square"), solid("poop"), solid("family")],
  },
  {
    id: "places",
    icon: solid("map-location"),
    image: Places,
    subfeatures: [
      solid("map-location-dot"),
      solid("heart"),
      solid("location-smile"),
    ],
  },
  {
    id: "feed",
    icon: solid("photo-film"),
    image: Feed,
    subfeatures: [solid("camera"), solid("comments")],
  },
  {
    id: "explore-people",
    icon: solid("users"),
    image: ExplorePeople,
    subfeatures: [solid("street-view"), solid("paper-plane")],
  },
];

const teamMembers: { name: string; role: string; image: string }[] = [
  {
    name: "Louise Helmre Livijn",
    role: "Co-Founder / CEO",
    image: Louise,
  },
  {
    name: "Fredrik Livijn",
    role: "Co-Founder / CTO",
    image: Fredrik,
  },
  {
    name: "André Andersson",
    role: "Co-Founder / COO",
    image: Andre,
  },
  {
    name: "Sofia Klemming",
    role: t("Cert. Dog Psychologist"),
    image: Sofia,
  },
  {
    name: "Ceasar",
    role: t("Office Mascot"),
    image: Ceasar,
  },
];

const line = (
  <svg
    aria-hidden="true"
    viewBox="0 0 418 42"
    className="absolute bottom-0 z-0 left-0 h-8 w-full fill-current text-primary-500 opacity-30"
    preserveAspectRatio="none"
  >
    <path d="M203.371.916c-26.013-2.078-76.686 1.963-124.73 9.946L67.3 12.749C35.421 18.062 18.2 21.766 6.004 25.934 1.244 27.561.828 27.778.874 28.61c.07 1.214.828 1.121 9.595-1.176 9.072-2.377 17.15-3.92 39.246-7.496C123.565 7.986 157.869 4.492 195.942 5.046c7.461.108 19.25 1.696 19.17 2.582-.107 1.183-7.874 4.31-25.75 10.366-21.992 7.45-35.43 12.534-36.701 13.884-2.173 2.308-.202 4.407 4.442 4.734 2.654.187 3.263.157 15.593-.78 35.401-2.686 57.944-3.488 88.365-3.143 46.327.526 75.721 2.23 130.788 7.584 19.787 1.924 20.814 1.98 24.557 1.332l.066-.011c1.201-.203 1.53-1.825.399-2.335-2.911-1.31-4.893-1.604-22.048-3.261-57.509-5.556-87.871-7.36-132.059-7.842-23.239-.254-33.617-.116-50.627.674-11.629.54-42.371 2.494-46.696 2.967-2.359.259 8.133-3.625 26.504-9.81 23.239-7.825 27.934-10.149 28.304-14.005.417-4.348-3.529-6-16.878-7.066Z" />
  </svg>
);

export default function Home() {
  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    const timeout = setTimeout(() => {
      setCurrentIndex((prevState) => {
        return prevState === features.length - 1 ? 0 : prevState + 1;
      });
    }, 5000);

    return () => clearTimeout(timeout);
  }, [currentIndex]);

  return (
    <>
      <div id="home" className="bg-glow">
        <section className="w-full max-w-screen-xl mx-auto px-6 py-12 md:px-12 xl:px-0 lg:py-20 xl:py-32 flex flex-col justify-around lg:flex-row">
          <div className="flex-1 max-w-md mx-auto lg:mx-0 lg:max-w-none">
            <h1 className="hidden">Dogs</h1>

            <h2 className="relative w-full text-5xl md:text-6xl lg:text-7xl xl:text-8xl font-bold text-center whitespace-nowrap">
              {t("A")} {t("happier")} <br />
              {t("and")}{" "}
              <span className="relative">
                <span className="relative z-10 text-primary-500">
                  {t("healthier")}
                </span>
                {line}
              </span>{" "}
              <br />
              {t("dog life")}.
            </h2>
            <p className="mt-6 lg:mt-12 text-gray-800 text-lg lg:text-xl xl:text-2xl xl:px-12 leading-relaxed text-center">
              {t("Intro Text")}
            </p>

            <div className="mt-6 mb-6 lg:mb-0 lg:mt-12 flex justify-center items-center space-x-3">
              <a href="https://apps.apple.com/se/app/dogs/id1601668360">
                <img
                  className="h-10 lg:h-12 w-auto px-2 py-0.5 bg-black rounded"
                  src={AppStore}
                  alt="App Store"
                />
              </a>

              <a href="https://play.google.com/store/apps/details?id=com.getdogsapp">
                <img
                  className="h-10 lg:h-12 w-auto px-2 py-0.5 bg-black rounded"
                  src={GooglePlay}
                  alt="Google Play"
                />
              </a>
            </div>
          </div>

          <div className="relative w-full lg:w-1/3 flex flex-col items-center mt-16 mb-36 lg:my-0">
            <div className="flex items-center justify-center">
              <div className="absolute left-1/2 top-0 transform -translate-x-1/2 w-64 h-64 lg:w-80 lg:h-80 xl:w-96 xl:h-96 bg-white bg-opacity-50 rounded-full border border-primary-200/50">
                <div className="w-full h-full bg-primary-200/50 rounded-full animate-pulsating" />

                {features.map((feature, i) => (
                  <div
                    key={i}
                    className="absolute top-1/2 left-1/2 w-2/3 h-2/3 transition-transform duration-500"
                    style={{
                      transform: `translateX(-50%) translateY(-50%) rotate(${
                        ((i - currentIndex) * 360) / features.length + 180
                      }deg)`,
                    }}
                  >
                    <button
                      className="absolute -top-7 lg:-top-8 xl:-top-11 left-1/2 bg-primary-500 text-white h-8 w-8 lg:h-10 lg:w-10 flex items-center justify-center rounded-full transform -translate-y-full -translate-x-1/2 transition"
                      onClick={() => setCurrentIndex(i)}
                    >
                      <FontAwesomeIcon
                        icon={feature.icon}
                        className="transition-all duration-500"
                        style={{
                          transform: `rotate(${
                            ((i - currentIndex) * -360) / features.length + 180
                          }deg)`,
                        }}
                      />
                    </button>
                  </div>
                ))}
              </div>

              <div className="transform translate-y-10 relative z-10 w-32 h-64 lg:h-80 lg:w-40 xl:w-48 xl:h-96 overflow-hidden border-4 border-primary-100 rounded-xl lg:rounded-3xl">
                {features.map((feature, i) => (
                  <FadeTransition key={i} visible={currentIndex === i}>
                    <img
                      className="absolute inset-0 object-cover"
                      src={feature.image}
                      alt={t(`Feature ${i + 1} Title`)}
                    />
                  </FadeTransition>
                ))}
              </div>
            </div>

            {features.map((feature, i) => (
              <FadeTransition key={i} visible={currentIndex === i}>
                <div className="z-30 absolute top-full left-1/2 transform lg:-translate-y-32 xl:-translate-y-36 -translate-x-1/2 w-full max-w-md bg-white p-6 text-gray-700 border border-primary-900/15 shadow-xl shadow-primary-900/5 rounded-xl">
                  <div className="absolute left-1/2 top-0 transform -translate-x-1/2 -translate-y-1/2 bg-primary-500 text-white text-lg h-10 w-10 lg:h-12 lg:w-12 flex items-center justify-center rounded-full border-2 border-white">
                    <FontAwesomeIcon icon={feature.icon} />
                  </div>

                  <h3 className="mt-2 font-bold lg:text-lg text-black text-center">
                    {t(`Feature ${i + 1} Title`)}
                  </h3>
                  <p className="text-gray-700 text-center line-clamp-2">
                    {t(`Feature ${i + 1} Text`)}
                  </p>

                  <div className="mt-3 lg:mt-6 text-center">
                    <a
                      href={`#${feature.id}`}
                      className="bg-secondary-500 py-1.5 px-4 rounded-full text-white font-bold text-xs md:text-sm hover:bg-secondary-600"
                    >
                      {t("Read more")}
                      <FontAwesomeIcon
                        className="ml-2"
                        icon={solid("angle-right")}
                      />
                    </a>
                  </div>
                </div>
              </FadeTransition>
            ))}
          </div>
        </section>
      </div>

      <div className="bg-white py-6 lg:p-12 grid grid-cols-2 md:grid-cols-3 gap-3 lg:gap-12">
        <div className="col-span-2 md:col-span-1 text-center">
          <div className="flex items-center justify-center font-black text-black text-3xl lg:text-4xl xl:text-5xl">
            4.8
            <span className="text-xl lg:text-2xl xl:text-3xl">
              <FontAwesomeIcon icon={solid("star-sharp")} />
            </span>
          </div>
          <div className="font-bold text-xs lg:text-base uppercase text-gray-400">
            {t("App Rating")}
          </div>
        </div>
        <div className="text-center">
          <div className="flex items-center justify-center font-black text-black text-3xl lg:text-4xl xl:text-5xl">
            50+
          </div>
          <div className="font-bold text-xs lg:text-base uppercase text-gray-400">
            {t("Training Modules")}
          </div>
        </div>
        <div className="text-center">
          <div className="flex items-center justify-center font-black text-black text-3xl lg:text-4xl xl:text-5xl">
            10,000+
          </div>
          <div className="font-bold text-xs lg:text-base uppercase text-gray-400">
            {t("Dog Friendly Places")}
          </div>
        </div>
      </div>

      <section id="features" className="bg-primary-100">
        {features.map((feature, i) => (
          <div
            key={i}
            id={feature.id}
            className={`py-12 px-6 md:px-12 xl:px-0 md:py-20 flex flex-col justify-center items-center ${
              i % 2 === 0 ? "lg:flex-row" : "lg:flex-row-reverse"
            }`}
          >
            <img
              className="relative z-20 m-3 w-40 lg:w-48 xl:w-56 h-auto rounded-xl lg:rounded-3xl shadow-2xl shadow-primary-700/20 border-2 lg:border-4 border-white"
              src={feature.image}
              alt={t(`Feature ${i + 1} Title`)}
            />

            <div
              className={`relative z-10 w-full max-w-xl pt-6 lg:pt-0 ${
                i % 2 === 0 ? "lg:pl-12" : "lg:pr-12"
              }`}
            >
              <div
                className={`absolute top-0 transform -translate-y-1/2 z-0 text-10xl text-primary-500 opacity-10 ${
                  i % 2 === 0 ? "left-0" : "-left-12"
                }`}
              >
                <FontAwesomeIcon icon={feature.icon} />
              </div>
              <h2 className="relative z-10 text-3xl sm:text-4xl lg:text-5xl font-black text-black">
                {t(`Feature ${i + 1} Title`)}
              </h2>
              <p className="mt-3 lg:text-lg text-primary-900">
                {t(`Feature ${i + 1} Text`)}
              </p>

              <div className="mt-6 grid grid-cols-1 sm:grid-cols-2 gap-6">
                {feature.subfeatures.map((icon, subIndex) => (
                  <div key={subIndex} className="flex items-center">
                    <div className="flex-shrink-0 bg-secondary-500 text-white h-9 w-9 flex items-center justify-center rounded-full">
                      <FontAwesomeIcon icon={icon} />
                    </div>

                    <div className="ml-3 text-primary-900 leading-tight">
                      {t(`Feature ${i + 1} Subfeature ${subIndex + 1}`)}
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        ))}
      </section>

      <section id="about-us" className="relative bg-white overflow-hidden">
        <div
          id="about-us-background"
          className="m-6 mb-0 md:mb-6 rounded-xl h-48 md:h-auto relative md:absolute md:inset-y-0 md:right-0 md:w-1/2"
        >
          <div className="absolute right-2 bottom-2 bg-white px-2 py-1 z-10 text-2xs rounded">
            {t("Photo by")}{" "}
            <a
              className="hover:underline"
              href="https://unsplash.com/@lindaseger?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText"
              target="_blank"
              rel="noreferrer"
            >
              Linda Segerfeldt
            </a>
          </div>
        </div>

        <div className="max-w-screen-xl mx-auto">
          <div className="relative z-10 bg-white md:w-full md:max-w-lg lg:max-w-2xl xl:max-w-3xl">
            <svg
              className="hidden md:block absolute right-0 inset-y-0 h-full w-48 text-white transform translate-x-1/2"
              fill="currentColor"
              viewBox="0 0 100 100"
              preserveAspectRatio="none"
            >
              <polygon points="50,0 100,0 50,100 0,100" />
            </svg>

            <div className="relative p-6 md:py-12 lg:py-20 max-w-2xl mx-auto">
              <h2 className="font-black text-gray-900 text-3xl sm:text-4xl lg:text-5xl">
                {t("The Dogs Gang")}
                <span className="text-primary-500">.</span>
              </h2>
              <p className="mt-3 md:mt-6 lg:text-lg text-black text-opacity-75 whitespace-pre-line">
                {t("About Us Text")}
              </p>

              <div className="mt-12 grid sm:grid-cols-2 gap-6">
                {teamMembers.map((member, i) => (
                  <div key={i} className="flex items-center">
                    <img
                      className="h-16 w-16 sm:h-20 sm:w-20 object-cover rounded-full mr-3"
                      src={member.image}
                      alt={member.name}
                    />

                    <div className="flex-1">
                      <h4 className="text-lg font-bold text-black">
                        {member.name}
                      </h4>
                      <p className="text-gray-600">{member.role}</p>
                    </div>
                  </div>
                ))}
              </div>

              <div className="mt-16 flex flex-col sm:flex-row items-center justify-center">
                <div className="text-lg text-gray-700">
                  {t("Do you want to work with us?")}
                </div>

                <a
                  href="mailto:info@getdogsapp.com"
                  className="mt-3 sm:mt-0 sm:ml-3 bg-white border border-gray-200 text-black font-bold text-sm px-4 py-2 rounded-full hover:border-gray-400"
                >
                  {t("Send us an email")}
                  <FontAwesomeIcon className="ml-2" icon={solid("hand-love")} />
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
