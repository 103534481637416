import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import en from "./en.json";
import sv from "./sv.json";

const params = new URLSearchParams(window.location.search);
export const lng = params.get("lang") ?? navigator.language;

i18n.use(initReactI18next).init({
  resources: {
    en: { app: en },
    sv: { app: sv },
  },
  lng,
  fallbackLng: "en",
  defaultNS: "app",
  parseMissingKeyHandler: (key) => {
    console.log(`MISSING: ${key}`);
    return `MISSING: ${key}`;
  },
  interpolation: {
    escapeValue: false,
  },
});

const t = i18n.t;

export { t };
