import React, { useCallback } from "react";
import { CSSTransition } from "react-transition-group";

interface FadeTransitionProps {
  visible: boolean;
  children: JSX.Element;
}

export default function FadeTransition({
  visible,
  children,
}: FadeTransitionProps) {
  const nodeRef = React.useRef(null);

  const child = useCallback(
    () => React.cloneElement(children, { ref: nodeRef }),
    []
  );

  return (
    <CSSTransition
      nodeRef={nodeRef}
      in={visible}
      timeout={300}
      classNames="fade"
      mountOnEnter={true}
      unmountOnExit={true}
    >
      {child}
    </CSSTransition>
  );
}
