import React from "react";
import AppStore from "@assets/AppStore.png";
import GooglePlay from "@assets/GooglePlay.png";
import { t } from "@/localization/setup";
import Training from "@assets/Prints/Training.png";
import Medicine from "@assets/Prints/Medicine.png";

export default function Share() {
  return (
    <section className="px-4 py-8 md:py-20">
      <article className="relative prose lg:prose-lg mx-auto pb-20">
        <h1 className="text-center">{t("Download Dogs")}.</h1>

        <div className="flex justify-center items-end max-w-xs mx-auto">
          <img
            className="w-2/5 sm:w-1/3 lg:w-1/2 h-auto rounded-xl lg:rounded-3xl border-4 border-primary-200/30"
            src={Training}
            alt="Dogs App Feed"
          />
          <img
            className="-ml-12 z-10 w-1/4 sm:w-1/4 lg:w-1/3 h-auto rounded-xl lg:rounded-2xl border-2 border-primary-200/30"
            src={Medicine}
            alt="Dogs App Medicine"
          />
        </div>

        <div className="md:mt-6 flex justify-center items-center space-x-3">
          <a href="https://apps.apple.com/se/app/dogs/id1601668360">
            <img className="h-12 w-auto" src={AppStore} alt="Apple Logo" />
          </a>

          <a href="https://play.google.com/store/apps/details?id=com.getdogsapp">
            <img className="h-12 w-auto" src={GooglePlay} alt="Google Logo" />
          </a>
        </div>
      </article>
    </section>
  );
}
