import React, { useEffect } from "react";
import AppStore from "@assets/AppStore.png";
import GooglePlay from "@assets/GooglePlay.png";
import { t } from "@/localization/setup";

export default function Share() {
  useEffect(() => {
    const url = window.location.pathname.substring(1);
    window.location.replace(`dogs://${url}`);
  }, []);

  return (
    <section className="px-4 py-8 md:py-20">
      <article className="relative prose lg:prose-lg mx-auto pb-20">
        <h1 className="text-center">{t("Shared content")}.</h1>
        <p className="text-center">
          {t("Download our app to view this content.")}
        </p>

        <div className="mt-6 flex justify-center items-center space-x-3">
          <a href="https://apps.apple.com/se/app/dogs/id1601668360">
            <img className="h-12 w-auto" src={AppStore} alt="Apple Logo" />
          </a>

          <a href="https://play.google.com/store/apps/details?id=com.getdogsapp">
            <img className="h-12 w-auto" src={GooglePlay} alt="Google Logo" />
          </a>
        </div>
      </article>
    </section>
  );
}
